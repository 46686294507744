<div
  class="sticky top-0 z-10 flex items-center justify-between rounded-t-3 border-b border-grey-200 bg-white px-md py-xs"
>
  @if (title(); as title) {
    <span class="heading-base">{{ title }}</span>
  }
  <button (click)="close()" class="btn btn-icon" role="button">
    <fa-icon [icon]="faTimes" size="lg"></fa-icon>
  </button>
</div>

<ng-content select="cft-dialog-content"></ng-content>
<ng-content select="cft-dialog-footer"></ng-content>
