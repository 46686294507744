import {ChangeDetectionStrategy, Component} from '@angular/core'

@Component({
  selector: 'cft-dialog-content',
  template: '<ng-content/>',
  styles: `
  :host {
    @apply block px-md pt-sm pb-lg;
  }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {}
