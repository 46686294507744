import {Component, EventEmitter, HostBinding, inject, input, OnDestroy, OnInit, Output} from '@angular/core'
import {NavigationStart, Router} from '@angular/router'
import {filter, takeUntil} from 'rxjs/operators'
import {Subject} from 'rxjs'
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes'
import {DialogRef} from '@angular/cdk/dialog'

@Component({
  selector: 'cft-dialog',
  templateUrl: './dialog.component.html',
  styles: `
    :host {
      @apply block max-h-[90vh] min-h-[250px] w-full space-y-xl overflow-auto rounded-t-3 bg-white shadow-lg sm:rounded-3;
    }
    :host(.cft-dialog--sm) {
      @apply sm:w-[368px];
    }
    :host(.cft-dialog--md) {
      @apply sm:w-[560px];
    }
    :host(.cft-dialog--lg) {
      @apply md:w-[752px];
    }
  `,
})
export class DialogComponent<Output> implements OnInit, OnDestroy {
  readonly title = input.required<string>()
  readonly size = input<'sm' | 'md' | 'lg'>('md')

  @HostBinding('class')
  get sizeClass() {
    return `cft-dialog--${this.size()}`
  }
  @Output() readonly closed = new EventEmitter<Output | undefined>()

  private readonly router = inject(Router)
  private readonly dialogRef? = inject(DialogRef, {optional: true})

  readonly faTimes = faTimes
  private readonly unsubscribe$ = new Subject<void>()

  close(data?: Output): void {
    this.closed.emit(data)
    this.dialogRef?.close(data)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit(): void {
    // close on route changes
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.close()
      })
  }
}
